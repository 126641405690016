<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="error"-->
<!--                effect="dark" style="margin-bottom: 20px" >-->
<!--            </el-alert>-->
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "ChuYou",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://admin.jzybyy.com/static/chuyou/1002011.png','id':'5538','search':''},        //5538	1002011	感冒灵颗粒
        {'index':1,'url':'http://admin.jzybyy.com/static/chuyou/1002100.png','id':'5611','search':''},        //5611	1002100	复方氨酚烷胺胶囊
        {'index':2,'url':'http://admin.jzybyy.com/static/chuyou/1014891.png','id':'13505','search':''},       //13505	1014891	氯雷他定片
        {'index':3,'url':'http://admin.jzybyy.com/static/chuyou/1002833.png','id':'6194','search':''},        //6194	1002833	诺氟沙星胶囊

        {'index':4,'url':'http://admin.jzybyy.com/static/chuyou/1001954.png','id':'5487','search':''},        //5487	1001954	蒙脱石散
        {'index':5,'url':'http://admin.jzybyy.com/static/chuyou/1002121.png','id':'5630','search':''},        //5630	1002121	云南白药气雾剂
        {'index':6,'url':'http://admin.jzybyy.com/static/chuyou/1006962.png','id':'9192','search':''},        //9192	1006962	速效救心丸
        {'index':7,'url':'http://admin.jzybyy.com/static/chuyou/1008883.png','id':'10344','search':''},       //10344	1008883	氨溴特罗口服溶液

        {'index':8,'url':'http://admin.jzybyy.com/static/chuyou/1002842.png','id':'6203','search':''},        //6203	1002842	氯雷他定片
        {'index':9,'url':'http://admin.jzybyy.com/static/chuyou/1003011.png','id':'6341','search':''},        //6341	1003011	茶苯海明片
        {'index':10,'url':'http://admin.jzybyy.com/static/chuyou/1000191.png','id':'4245','search':''},       //4245	1000191	咳特灵胶囊
        {'index':11,'url':'http://admin.jzybyy.com/static/chuyou/1009235.png','id':'10564','search':''},      //10564	1009235	硝酸甘油片

        {'index':12,'url':'http://admin.jzybyy.com/static/chuyou/1014567.png','id':'13341','search':''},      //13341	1014567	医用外科口罩(平面耳挂灭菌型)
        {'index':13,'url':'http://admin.jzybyy.com/static/chuyou/1002987.png','id':'6321','search':''},       //6321	1002987	晕车贴
        {'index':13,'url':'http://admin.jzybyy.com/static/chuyou/1000023.png','id':'4168','search':''},       //1000023	4168	叶优R75%酒精消毒喷雾
        {'index':15,'url':'http://admin.jzybyy.com/static/chuyou/1013709.png','id':'12917','search':''},       //12917	1013709	金银花花露水


        {'index':16,'url':'http://admin.jzybyy.com/static/chuyou/1015293.png','id':'13753','search':''},      //13753	1015293	一次性使用医用口罩(耳挂式 儿童型)(淮海)
        {'index':17,'url':'http://admin.jzybyy.com/static/chuyou/1006779.png','id':'9073','search':''},       //9073	1006779	晕车贴
        {'index':18,'url':'http://admin.jzybyy.com/static/chuyou/1008982.png','id':'10409','search':''},      //10409	1008982	利尔康牌75%酒精消毒液
        {'index':19,'url':'http://admin.jzybyy.com/static/chuyou/1013707.png','id':'12915','search':''},      //12915	1013707	蛇胆牛黄花露水

        // {'index':28,'url':'http://admin.jzybyy.com/static/new/1011842.png','id':'12023','search':''},
        // {'index':29,'url':'http://admin.jzybyy.com/static/new/1013792.png','id':'12950','search':''},
        // {'index':30,'url':'http://admin.jzybyy.com/static/new/1010794.png','id':'11436','search':''},
        // {'index':31,'url':'http://admin.jzybyy.com/static/new/1014942.png','id':'13529','search':''},
      ],
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
